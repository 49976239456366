import React from 'react';
import { Box } from '@mui/material';
import { arrayOf, number, string, shape } from 'prop-types';
import GraphImage from '@/components/GraphImage';
import Link from '../../Link';

const ImageBarBlock = ({ id, images }) => (
  <Box display="flex" justifyContent="space-evenly" flexWrap="wrap">
    {images?.map(({ id: assetId, handle, alt, href, width, height }) => (
      <Box
        key={`${id}-${assetId}`}
        width={{
          xs: '50%',
          sm: 1 / images.length,
        }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box width="100%" maxWidth={168}>
          {href ? (
            <Link href={href}>
              <GraphImage
                image={{
                  handle,
                  width,
                  height,
                }}
                alt={alt}
                maxWidth={168}
                withWebp
                blurryPlaceholder={false}
              />
            </Link>
          ) : (
            <GraphImage
              image={{
                handle,
                width,
                height,
              }}
              alt={alt}
              maxWidth={168}
              withWebp
              blurryPlaceholder={false}
            />
          )}
        </Box>
      </Box>
    ))}
  </Box>
);

ImageBarBlock.propTypes = {
  id: string.isRequired,
  images: arrayOf(
    shape({
      id: string.isRequired,
      alt: string.isRequired,
      handle: string.isRequired,
      href: string,
      width: number.isRequired,
      height: number.isRequired,
    }),
  ).isRequired,
};

export default ImageBarBlock;
