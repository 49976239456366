import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { arrayOf, func, number, object, oneOf, oneOfType, shape, string } from 'prop-types';

import GraphImage from '@/components/GraphImage';
import { LINK_TYPE } from '@/types';
import Link from '../../Link';

// #region Hooks
const useClientCheck = () => {
  const [isClient, isClientSet] = useState(false);
  useEffect(() => {
    isClientSet(true);
  }, []);
  return isClient;
};

const useHeight = containerRef => {
  const [height, setHeight] = useState(0);
  const [isset, setIsset] = useState(false);

  const handleResize = () => {
    setHeight(containerRef.current?.clientHeight);
  };

  // componentDidMount
  useEffect(() => {
    // update on ref element resize
    containerRef.current.addEventListener('resize', () => {
      handleResize();
    });

    // update on windows resize
    window.addEventListener('resize', () => {
      handleResize();
    });

    return () => {
      window.removeEventListener('resize', handleResize);
      if (containerRef.current) {
        containerRef.current.removeEventListener('resize', handleResize);
      }
    };
  }, []);

  // componentDidUpdate
  useEffect(() => {
    if (!isset && containerRef.current?.clientHeight && containerRef.current?.clientHeight !== height) {
      handleResize();
      setIsset(true);
    }
  }, [containerRef.current?.clientHeight]);

  return height;
};

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // only execute all the code below in client side
    // Handler to call on window resize
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
};
// #endregion

// #region Functions
const shapeData = (columns, numOfCols) => {
  const slicedColumns = [];
  let rowIndex = 0;
  let colIndex = 0;

  columns.forEach(item => {
    if (!slicedColumns[colIndex]) {
      slicedColumns[colIndex] = [];
    }

    slicedColumns[colIndex][rowIndex] = item;

    if (colIndex >= numOfCols - 1) {
      rowIndex += 1;
      colIndex = 0;
    } else {
      colIndex += 1;
    }
  });

  return slicedColumns;
};

const handleMaxColHeight = (
  currMaxColHeight,
  numOfCols,
  rIndex,
  colItemHeights,
  defaultHeight,
  currColHeights,
  setMaxColHeight,
) => {
  let tempMaxColHeight = 0;

  for (let i = 0; i < numOfCols; i += 1) {
    const loopItemStateKey = `${i}-${rIndex}`;
    const loopColItemHeightFromState = colItemHeights[loopItemStateKey];

    // make sure the column item height is set to default before getting its column height to check if its the maximum
    if (loopColItemHeightFromState === defaultHeight) {
      const loopCurrColHeight = currColHeights[i];

      // update the height of the longest column
      if (loopCurrColHeight > tempMaxColHeight) {
        tempMaxColHeight = loopCurrColHeight;
      }
    }
  }

  if (tempMaxColHeight !== currMaxColHeight) {
    setMaxColHeight(tempMaxColHeight);
  }
};

const resetItemHeights = (numOfCols, rIndex, currItemActualHeights, changeColItemHeight, defaultHeight, setMaxColHeight) => {
  for (let i = 0; i < numOfCols; i += 1) {
    const loopItemStateKey = `${i}-${rIndex}`;
    const isValidCurrItemHeight = currItemActualHeights[loopItemStateKey] > 0;

    if (isValidCurrItemHeight) {
      changeColItemHeight(loopItemStateKey, defaultHeight);
      setMaxColHeight(0);
    }
  }
};
// #endregion

// #region Protypes

const imageShape = shape({
  alt: string.isRequired,
  url: string.isRequired,
  handle: string,
  mimeType: string.isRequired,
  width: number,
  height: number,
});

const columnShape = shape({
  title: string.isRequired,
  description: string.isRequired,
  image: imageShape,
  link: LINK_TYPE,
});

const commonThemeItemPropTypes = {
  titleColor: string.isRequired,
  bodyColor: string.isRequired,
};

const themeShape = shape({
  backgroundColor: string.isRequired,
  ...commonThemeItemPropTypes,
});

const alignShape = oneOf(['left', 'center']);

const commonColPropTypes = {
  numOfCols: number.isRequired,
  cIndex: number.isRequired,
  currColHeights: arrayOf(object).isRequired,
  changeCurrColHeights: func.isRequired,
  itemWidth: oneOfType([number, string]).isRequired,
  colItemHeights: object.isRequired,
  changeColItemHeight: func.isRequired,
  currItemActualHeights: object.isRequired,
  changeCurrItemActualHeights: func.isRequired,
  maxColHeight: oneOfType([number, string]).isRequired,
  setMaxColHeight: func.isRequired,
};

const colItemPropsShape = shape({
  align: alignShape.isRequired,
  ...commonThemeItemPropTypes,
});

// #endregion

// #region Compnents
// #region MasonryBlock
const MasonryBlock = ({ align, columns, numOfCol, theme: { bodyColor, titleColor } }) => {
  const isClient = useClientCheck();

  const winDim = useWindowSize();

  const [numOfCols, setNumOfCols] = useState(numOfCol);
  const [slicedColumns, setSlicedColumns] = useState(shapeData(columns, numOfCols));
  const [currColHeights, setCurrColHeights] = useState({});
  const [colItemHeights, setColItemHeights] = useState({});
  const [currItemActualHeights, setCurrItemActualHeights] = useState({});
  const [maxColHeight, setMaxColHeight] = useState(0);

  const [itemWidth, setItemWidth] = useState(`${Math.floor(90 / numOfCols)}%`);
  const [alignContent, setAlignContent] = useState(align);

  const smallDeviceNumOfCol = 1;

  let currWinDim;
  useEffect(() => {
    currWinDim = winDim.width;
  }, []);
  const resetDefaultHeights = () => {
    if (winDim.width !== currWinDim) {
      setCurrColHeights({});
      setColItemHeights({});
      setCurrItemActualHeights({});
      setMaxColHeight(0);

      currWinDim = winDim.width;
    }
  };

  useEffect(() => {
    if (winDim.width && winDim.width < 768 && numOfCols !== smallDeviceNumOfCol) {
      setNumOfCols(smallDeviceNumOfCol);
      setAlignContent('center');
    } else if (winDim.width && winDim.width > 768 && numOfCols !== numOfCol) {
      setNumOfCols(numOfCol);
    }
  }, [winDim.width]);

  useEffect(() => {
    resetDefaultHeights();

    const newSlicedCol = shapeData(columns, numOfCols);
    setSlicedColumns(newSlicedCol);

    const newItemWidth = `${Math.floor(90 / numOfCols)}%`;
    setItemWidth(newItemWidth);
  }, [numOfCols]);

  const changeColItemHeight = (key, heigthValue) => {
    let value = heigthValue;

    if (numOfCols === smallDeviceNumOfCol) {
      value = 'fit-content';
    }

    setColItemHeights(prevState => ({ ...prevState, [key]: value }));
  };

  const changeCurrItemActualHeights = (key, value) => {
    setCurrItemActualHeights(prevState => ({ ...prevState, [key]: value }));
  };

  const changeCurrColHeights = (key, value) => {
    setCurrColHeights(prevState => ({ ...prevState, [key]: value }));
  };

  if (!isClient) return null;
  return (
    <Box display="flex" flexDirection="row" justifyContent="center" alignItems="flex-start" height="max-content">
      {slicedColumns.map((cols, cIndex) => (
        <MasonryCol
          key={Number(cIndex)}
          itemProps={{ align: alignContent, titleColor, bodyColor }}
          {...{
            numOfCols,
            cols,
            cIndex,
            currColHeights,
            changeCurrColHeights,
            itemWidth,
            currItemActualHeights,
            changeCurrItemActualHeights,
            colItemHeights,
            changeColItemHeight,
            maxColHeight,
            setMaxColHeight,
          }}
        />
      ))}
    </Box>
  );
};

MasonryBlock.propTypes = {
  align: alignShape.isRequired,
  columns: arrayOf(columnShape).isRequired,
  numOfCol: number,
  theme: themeShape.isRequired,
};

MasonryBlock.defaultProps = {
  numOfCol: 3,
};
// #endregion MasonryBlock

// #region MasonryCol
const MasonryCol = ({
  numOfCols,
  cols,
  cIndex,
  currColHeights,
  changeCurrColHeights,
  itemWidth,
  itemProps,
  colItemHeights,
  changeColItemHeight,
  currItemActualHeights,
  changeCurrItemActualHeights,
  maxColHeight,
  setMaxColHeight,
}) => {
  const colRef = useRef();
  let currColHeight = useHeight(colRef);

  const colLength = cols.length;

  const getColHeight = () => {
    if (maxColHeight > 0) {
      return maxColHeight;
    }

    return '100%';
  };
  const [colHeight, setColItemHeights] = useState(getColHeight());

  useEffect(() => {
    currColHeight = null;
  }, [numOfCols]);

  useEffect(() => {
    changeCurrColHeights(cIndex, currColHeight);
  }, [currColHeight]);

  useEffect(() => {
    setColItemHeights(getColHeight());
  }, [maxColHeight]);

  return (
    <Box ref={colRef} display="flex" flexDirection="column" width={{ xs: '90%', md: itemWidth }} height={colHeight}>
      {cols.map(({ title, description, link, image }, rIndex) => (
        <MasonryColItem
          key={Number(rIndex)}
          itemProps={{ ...itemProps, image, title, description, link }}
          {...{
            cIndex,
            rIndex,
            numOfCols,
            itemWidth,
            colLength,
            currColHeights,
            maxColHeight,
            setMaxColHeight,
            colItemHeights,
            changeColItemHeight,
            currItemActualHeights,
            changeCurrItemActualHeights,
          }}
        />
      ))}
    </Box>
  );
};

MasonryCol.propTypes = {
  cols: arrayOf(columnShape).isRequired,
  itemProps: colItemPropsShape.isRequired,
  ...commonColPropTypes,
};
// #endregion

// #region MasonryColItem
const MasonryColItem = ({
  itemProps,
  cIndex,
  rIndex,
  numOfCols,
  itemWidth,
  colLength,
  currColHeights,
  colItemHeights,
  changeColItemHeight,
  currItemActualHeights,
  changeCurrItemActualHeights,
  maxColHeight,
  setMaxColHeight,
}) => {
  const itemStateKey = `${cIndex}-${rIndex}`;
  const defaultHeight = 'fit-content';
  const isLastColItemOfFirstCol = rIndex + 1 >= colLength && cIndex === 0;
  const isLastColItem = rIndex + 1 >= colLength;

  useEffect(() => {
    changeColItemHeight(itemStateKey, defaultHeight);

    if (isLastColItemOfFirstCol) {
      handleMaxColHeight(maxColHeight, numOfCols, rIndex, colItemHeights, defaultHeight, currColHeights, setMaxColHeight);
    }
  }, []);

  // reset heights
  useEffect(() => {
    // run the following codes for the last items of each column only
    if (isLastColItemOfFirstCol) {
      // reset the column item height to default
      // this allows the true height of the column to be measured in the maxHeight useEffect
      resetItemHeights(numOfCols, rIndex, currItemActualHeights, changeColItemHeight, defaultHeight, setMaxColHeight);
    }
  }, [currItemActualHeights]);

  // maxHeight useEffect
  useEffect(() => {
    // run the following codes for the last items of each column only
    if (isLastColItemOfFirstCol) {
      handleMaxColHeight(maxColHeight, numOfCols, rIndex, colItemHeights, defaultHeight, currColHeights, setMaxColHeight);
    }
  }, [colItemHeights]);

  const changeItemHeight = h => {
    changeCurrItemActualHeights(itemStateKey, h);
  };

  return (
    <MasonryItem
      key={Number(rIndex)}
      width={itemWidth}
      height={isLastColItem ? '100%' : 'fit-content'}
      setCurrItemHeight={changeItemHeight}
      {...itemProps}
    />
  );
};

MasonryColItem.propTypes = {
  itemProps: shape({ ...colItemPropsShape, ...imageShape }).isRequired,
  rIndex: number.isRequired,
  colLength: number.isRequired,
  ...commonColPropTypes,
};
// #endregion

// #region MasonryItem
const MasonryItem = ({
  width,
  height,
  setCurrItemHeight,
  align,
  image,
  titleColor,
  title,
  bodyColor,
  description,
  link,
  ...props
}) => {
  const itemRef = useRef();
  let currItemHeight = useHeight(itemRef);

  useEffect(() => {
    setCurrItemHeight(currItemHeight);

    return () => {
      currItemHeight = null;
    };
  }, [currItemHeight]);

  return (
    <Box
      ref={itemRef}
      minWidth={{ xs: '90%', md: width }}
      height={height}
      borderRadius={1}
      boxShadow={6}
      padding={4}
      display="flex"
      flexDirection="column"
      alignItems={align}
      mx={2}
      my={2}
      {...props}
    >
      {image && (
        <Box maxWidth={75}>
          {image.mimeType === 'image/svg+xml' ? (
            <img src={image.url} alt={image.alt} width={75} height={75} loading="lazy" />
          ) : (
            <GraphImage
              image={{
                handle: image.handle,
                width: image.width,
                height: image.height,
              }}
              alt={image.alt}
              withWebp
              blurryPlaceholder={false}
            />
          )}
        </Box>
      )}

      <Typography variant="h4" pb={1} color={titleColor} align={align}>
        {title}
      </Typography>

      <Typography variant="body2" color={bodyColor} align={align}>
        {description}
      </Typography>

      {link && (
        <Link href={link.href} variant="body2" fontFamily="h1.fontFamily" pt={1}>
          {link.label}
        </Link>
      )}
    </Box>
  );
};

MasonryItem.propTypes = {
  width: string,
  height: oneOfType([number, string]),
  setCurrItemHeight: func.isRequired,
  align: alignShape,
  ...columnShape,
  ...commonThemeItemPropTypes,
};

MasonryItem.defaultProps = {
  width: '30%',
  height: 'fit-content',
  align: 'left',
};
// #endregion

// #endregion

export default MasonryBlock;
