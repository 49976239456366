import React, { useCallback, useMemo } from 'react';

import { useRouter } from 'next/router';
import { shape, string } from 'prop-types';
import queryString from 'query-string';
import { useCookies } from 'react-cookie';

import { Box, Card, CardActionArea, CardContent, CardMedia, Typography, useTheme } from '@mui/material';

import { TEXT_TYPE } from '@/types';
import STATE_ABBR from '@/utils/stateAbbr';

import { track } from '../../../modules/segment';

const cardProps = theme => ({
  component: Box,
  sx: {
    border: `2px solid ${theme.palette.neutral.light}`,
    borderRadius: 1,
    maxWidth: 540,
    boxShadow: 'none',
    margin: '0 auto',
    marginBottom: 2,
    [theme.breakpoints.up('md')]: {
      maxWidth: 340,
      marginBottom: 0,
      mx: 1,
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 400,
    },
  },
});

const BookingEntryBlock = ({ theme: blockTheme, header, headerFallback, subheader, subheaderFallback }) => {
  const muiTheme = useTheme();
  const router = useRouter();

  const filteredQuery = router?.query
    ? Object.keys(router.query)
        .filter(
          key =>
            !key.includes('slug') &&
            !key.includes('city') &&
            !key.includes('cityGenSlug') &&
            !key.includes('state') &&
            !key.includes('serviceGenPage'),
        )
        .reduce(
          (obj, key) =>
            Object.assign(obj, {
              [key]: router.query[key],
            }),
          {},
        )
    : null;

  const truckQuery = `?${queryString.stringify({ ...filteredQuery, truck: 1 })}`;
  const laborQuery = `?${queryString.stringify({ ...filteredQuery, truck: 0 })}`;

  const [{ vercel_location: locationInfo }] = useCookies(['vercel_location']);
  const AVAILABLE_TOKENS = ['geolocationCity', 'geolocationRegion'];

  const containedTokenizedStrings = text =>
    text ? AVAILABLE_TOKENS.map(token => (text.includes(`{${token}}`) ? token : null)).filter(item => !!item) : [];

  const replaceAllTokens = useCallback(
    (text, fallback) => {
      if (!locationInfo) return fallback;

      // Replaces all tokens with data from cookie if all available, otherwise returns fallback
      const tokensContained = containedTokenizedStrings(text);
      const allTokenDataAvailable = tokensContained.every(token => locationInfo[token] && text.includes(`{${token}}`));

      if (locationInfo && allTokenDataAvailable) {
        // Tokens being used - out of country geolocation - use fallback always
        if (locationInfo.geolocationCountry && locationInfo.geolocationCountry !== 'US') return fallback;

        const newText = AVAILABLE_TOKENS.reduce((prevText, token) => {
          if (token === 'geolocationRegion') {
            // Special handling for region to replace with full state name instead of abbreviation
            const stateName = STATE_ABBR.find(state => state.abbreviation === locationInfo[token])?.name ?? 'null';

            return prevText.replace(`{${token}}`, stateName);
          }
          return prevText.replace(`{${token}}`, locationInfo[token]);
        }, text);

        if (newText.includes('null')) return fallback;
        return newText;
      }

      return fallback;
    },
    [locationInfo],
  );

  const isDynamicContent = useMemo(() => {
    // If content contains tokens - is dynamic - return true
    const headerTokensContained = header ? containedTokenizedStrings(header.text) : [];
    const subheaderTokensContained = subheader ? containedTokenizedStrings(subheader.text) : [];

    return {
      header: headerTokensContained?.length > 0,
      subheader: subheaderTokensContained?.length > 0,
    };
  }, [header, subheader]);

  return (
    <>
      {header || subheader ? (
        <Box mx="auto" pb={{ xs: 3, md: 8 }} textAlign="center">
          {header ? (
            <Typography
              color={blockTheme.titleColor}
              variant={header.variant}
              component={header.component}
              align={header.alignment}
              pb={subheader ? 2 : 0}
            >
              {isDynamicContent?.header ? replaceAllTokens(header.text, headerFallback) : header.text}
            </Typography>
          ) : null}
          {subheader ? (
            <Box maxWidth={600} mx="auto">
              <Typography
                color={blockTheme.bodyColor}
                variant={subheader.variant}
                component={subheader.component}
                align={subheader.alignment}
              >
                {isDynamicContent?.subheader ? replaceAllTokens(subheader.text, subheaderFallback) : subheader.text}
              </Typography>
            </Box>
          ) : null}
        </Box>
      ) : null}
      <Box display="flex" justifyContent="space-around" flexDirection={{ xs: 'column', md: 'row' }} maxWidth={820} mx="auto">
        <Card {...cardProps(muiTheme)}>
          <CardActionArea
            href={`${process.env.NEXT_PUBLIC_MARKETING_DOMAIN}/v2/book/location${truckQuery}`}
            onClick={() => {
              track('Choose Service - Continue', { service: 'Truck and Movers' });
            }}
          >
            <CardMedia component="img" src="/images/bookingEntry/movers.svg" />
            <CardContent>
              <Box height={118} px={2}>
                <Typography variant="h4">Truck and Movers</Typography>
                <Typography variant="body3" color="neutral.medium" paragraph pt={1}>
                  This package includes a team of movers, a 26-foot truck, and a licensed and insured truck driver.
                </Typography>
              </Box>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card {...cardProps(muiTheme)}>
          <CardActionArea
            href={`${process.env.NEXT_PUBLIC_MARKETING_DOMAIN}/v2/book/location${laborQuery}`}
            onClick={() => {
              track('Choose Service - Continue', { service: 'Movers Only' });
            }}
          >
            <CardMedia component="img" src="/images/bookingEntry/labor.svg" />
            <CardContent>
              <Box height={118} px={2}>
                <Typography variant="h4">Movers only</Typography>
                <Typography variant="body3" color="neutral.medium" paragraph pt={1}>
                  This package offers only movers, and is perfect for unloading/loading a storage unit or rearranging
                  furniture in your home.
                </Typography>
              </Box>
            </CardContent>
          </CardActionArea>
        </Card>
      </Box>
    </>
  );
};

BookingEntryBlock.propTypes = {
  theme: shape({
    titleColor: string.isRequired,
    bodyColor: string.isRequired,
  }).isRequired,
  header: TEXT_TYPE,
  headerFallback: string,
  subheader: TEXT_TYPE,
  subheaderFallback: string,
};

BookingEntryBlock.defaultProps = {
  header: null,
  headerFallback: '',
  subheader: null,
  subheaderFallback: '',
};

export default BookingEntryBlock;
